<template lang="pug">
.h-auto.flex.w-full
  .vx-col.mx-auto.self-center(
    class="sm:w-1/1 md:w-1/2 lg:w-3/4 xl:w-3/6"
  )
    vx-card
      form
        vs-row(
          vs-w="12"
        )
          vs-col(
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="10"
            vs-sm="10"
            vs-xs="12"
          )
            span.text-data(
              class="ml-4 mt-1"
            ) {{ $t("demo_signup.company_information") }}
        vs-divider
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="company_nit"
              v-validate="'required|numeric|max:50'"
              :data-vv-as="$t('demo_signup.nit')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.nit')"
              v-model="form.nit"
              autocomplete="off"
            )
            span.text-danger.text-sm(
              v-show="errors.has('company_nit')"
            ) {{ errors.first('company_nit') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="company_name"
              v-validate="'required|alpha_spaces|max:50'"
              :data-vv-as="$t('demo_signup.company_name')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.company_name')"
              v-model="form.name"
            )
            span.text-danger.text-sm(
              v-show="errors.has('company_name')"
            ) {{ errors.first('company_name') }}
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
              label(
                class="vs-select--label"
              ) {{ $t('demo_signup.country') }}
              v-select(
                :options="countries"
                label="name"
                name="country"
                :data-vv-as="$t('demo_signup.country')"
                v-validate="'required'"
                :clearable="false"
                :placeholder='$t("demo_signup.country")'
                v-model="country_id"
                autocomplete="off"
              )
                template(
                  slot="option"
                  slot-scope="option"
                ) {{ option.name | capitalize }}
              span.text-danger.text-sm(
                v-show="errors.has('country')"
              ) {{ errors.first('country') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="address"
              v-validate="'required|min:7|max:50'"
              :data-vv-as="$t('demo_signup.address')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.address')"
              v-model="form.address"
            )
            span.text-danger.text-sm(
              v-show="errors.has('address')"
            ) {{ errors.first('address') }}
        vs-divider
        vs-row(
          vs-w="12"
        )
          vs-col(
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="10"
            vs-sm="10"
            vs-xs="12"
          )
            span.text-data(
              class="ml-4 mt-1"
            ) {{ $t("demo_signup.contact_information") }}
        vs-divider
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="contact_first_name"
              v-validate="'required|alpha_spaces|max:50'"
              :data-vv-as="$t('demo_signup.contact_first_name')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.contact_first_name')"
              v-model="form.contact_first_name"
            )
            span.text-danger.text-sm(
              v-show="errors.has('contact_first_name')"
            ) {{ errors.first('contact_first_name') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="contact_last_name"
              v-validate="'required|alpha_spaces|max:50'"
              :data-vv-as="$t('demo_signup.contact_last_name')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.contact_last_name')"
              v-model="form.contact_last_name"
            )
            span.text-danger.text-sm(
              v-show="errors.has('contact_last_name')"
            ) {{ errors.first('contact_last_name') }}
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            label(
              class="vs-select--label"
            ) {{ $t('demo_signup.document_type') }}
            v-select(
              :options="documentTypes"
              label="name"
              name="document_type"
              :data-vv-as="$t('demo_signup.document_type')"
              v-validate="'required'"
              :clearable="false"
              :placeholder='$t("demo_signup.document_type")'
              v-model="document_type"
              autocomplete="off"
            )
              template(
                slot="option"
                slot-scope="option"
              ) {{ option.name | capitalize }}
            span.text-danger.text-sm(
              v-show="errors.has('document_type')"
            ) {{ errors.first('document_type') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="document"
              v-validate="'required|numeric'"
              :data-vv-as="$t('demo_signup.document')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.document')"
              v-model="form.document"
            )
            span.text-danger.text-sm(
              v-show="errors.has('document')"
            ) {{ errors.first('document') }}
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="contact_phone"
              v-validate="'required|numeric|min:7|max:50'"
              :data-vv-as="$t('demo_signup.contact_phone')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.contact_phone')"
              v-model="form.contact_phone"
            )
            span.text-danger.text-sm(
              v-show="errors.has('contact_phone')"
            ) {{ errors.first('contact_phone') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="contact_email"
              v-validate="'required|email'"
              :data-vv-as="$t('demo_signup.contact_email')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('demo_signup.contact_email')"
              v-model="form.contact_email"
            )
            span.text-danger.text-sm(
              v-show="errors.has('contact_email')"
            ) {{ errors.first('contact_email') }}
        div(
          class="flex justify-between text-center mt-5"
        )
          vs-button.style-buttom.space-buttom.buttom-save(
            color="primary"
            type="filled"
            @click.prevent="submitForm"
          ) {{$t('signup')}}
</template>
<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })
import HTTP from "@/http-common"
const NAME = 'SignUpForm'

export default {
  name: NAME,
  props: {
    absolute: {
      type: Boolean,
      required: false,
      default: false
    },
    apiURL: {
      type: String,
      required: false
    },
    smartQuickURL : {
      type: String,
      required: false
    }
  },
  data () {
    return {
      form: {
        name: '',
        nit: '',
        country_id: '',
        address: '',
        cost_center: '',
        date_start: '2020-07-16',
        date_end: '2020-09-16',
        is_actived: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_email: '',
        contact_phone: '',
        linked_company_id: '4054311d-78ab-4d4e-9ab5-5c1794d0367e',
        document_type: '',
        document: ''
      },
      country_id: '',
      document_type: '',
      countries: [],
      linked_companies: [],
      documentTypes: [],
      // apiURL: 'https://backend.smartquick.com.co',
      // smartQuickURL: 'https://enterprise.smartquick.com.co'
    }
  },
  mounted () {
    this.getCountries()
    this.getLinkedCompanies()
    this.getDocumentTypes()
  },
  methods: {
    /**
     * Limpia los errores del validador
     */
    clearErrors () {
      if (this.errors.any()) { this.errors.clear() }
    },
    resetForm () {
      for (let key in this.form) { this.form[key] = null }
      let self = this
      setTimeout(() => {
        self.clearErrors()
      }, 20)
    },
    async recaptcha () {
      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('signup')
      this.form.token = token
      this.sendInformation()
    },
    sendEmail () {
      HTTP.setHeader(false)
      HTTP.post(this.api, this.form)
        .then(() => {
          this.$notify.success(this.$t('mensaje_enviado'))
          this.resetForm()
        }, () => {
          this.$vs.notify({
            text: this.$t("ocurrio_un_error"),
            color: "#64cc52",
            iconPack: "feather",
            icon: "icon-check-circle"
          })
        })
    },
    submitForm () {
      let self = this
      self.$validator.validateAll().then((result) => {
        if (result) { self.recaptcha() }
      })
    },
    sendInformation () {
      this.form.country_id = this.country_id.id
      this.form.document_type = this.document_type.id
      HTTP.setHeader(false)
      HTTP.post(this.apiURL + '/companies/', this.form)
        .then(() => {
          this.resetForm()
          this.$vs.notify({
            text: this.$t("Depots.petition"),
            color: "#64cc52",
            iconPack: "feather",
            icon: "icon-check-circle"
          })
          // this.$bvModal.show('success-modal')
          // setTimeout(() => {
          //   window.location.href = this.smartQuickURL
          // }, 5000)
          // this.$notify.success(this.$t('mensaje_enviado'))
        }, () => {
          // this.$notify.error(this.$t('demo_signup.posting'))
        })
    },
    getCountries () {
      HTTP.get(this.apiURL + '/countries/')
        .then((res) => {
          let objectCountires = res.data.results.features ? res.data.results.features : []
          objectCountires.forEach(item => {
            this.countries.push({
              id: item.id,
              name: item.properties.country
            })
          })
        }, () => {
          // this.$notify.error(this.$t('demo_signup.countires_error'))
        })
    },
    getLinkedCompanies () {
      HTTP.get(this.apiURL + '/companies/linked_companies/')
        .then((res) => {
          this.linked_companies = res.data ? res.data : []
        }, () => {
          // this.$notify.error(this.$t('ocurrio_un_error'))
        })
    },
    getDocumentTypes () {
      HTTP.get(this.apiURL + '/users/choices')
        .then((res) => {
          let objectTypes = res.data.document_type ? res.data.document_type : {}
          Object.keys(objectTypes).forEach(item => {
            this.documentTypes.push({
              id: item,
              name: objectTypes['' + item]
            })
          })
        }, () => {
          // this.$notify.error(this.$t('ocurrio_un_error'))
        })
    }
  }
}
</script>
<style>
  .signup-label { margin-bottom: 0.2em; margin-left: 0.2em; }
</style>
