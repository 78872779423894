<template>
  <signup-form class="py-5" apiURL="https://backend.smartquick.com.co" smartQuickURL="https://enterprise.smartquick.com.co"></signup-form>
</template>
<script>
import SignUpForm from '@/components/demo/Form.vue'

export default {
  name: 'demo-signup',
  components: {
    'signup-form': SignUpForm
  }
}
</script>
